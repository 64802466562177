import cs from "classnames";
import React from "react";

import styles from "./index.module.less";

const FlexRow = (
  {
    children,
    size = 0,
    className,
    style,
    ...props
  }: {
    children: React.ReactNode;
    size?: number | string;
    className?: string;
    style?: React.CSSProperties;
  } & React.HTMLAttributes<HTMLDivElement>,
  ref: React.Ref<HTMLDivElement>
) => {
  return (
    <div
      className={cs(styles["flex-col"], className)}
      style={{
        ...style,
        gap: size,
      }}
      ref={ref as React.Ref<HTMLDivElement>}
      {...props}
    >
      {children}
    </div>
  );
};

export default React.forwardRef(FlexRow);
