import FlexCol from "@/ui/FlexCol";
import FlexRow from "@/ui/FlexRow";
import NewPCLandingSubPageLayout from "@/ui/NewPCLandingSubPageLayout";
import { Button, Modal, Typography } from "antd";
import Image from "next/image";
import { useState } from "react";
import ReactPlayer from "react-player";

import { HOME_LANDING_CHAT_ITEMS } from "@/constants/landing/landing";

import styles from "./index.module.less";

const ChatItem = ({
  paragraph,
  name,
  avatar,
  title,
  width,
}: {
  paragraph: string;
  name: string;
  avatar: string;
  title: string;
  width: string;
}) => {
  return (
    <FlexCol
      className={styles["chat-item"]}
      size={12}
      style={{
        width,
      }}
    >
      <Image src={"/newimages/public/quote.svg"} width={24} height={24} alt="quote" />

      <Typography.Paragraph className={styles["chat-item-paragraph"]}>
        {paragraph}
      </Typography.Paragraph>

      <FlexRow size={4}>
        <Image className={styles["chat-avatar"]} src={avatar} width={32} height={32} alt="user" />
        <Typography.Text className={styles["chat-username"]}>{name}</Typography.Text>
        <Typography.Text className={styles["chat-job-title"]}>/</Typography.Text>
        <Typography.Text className={styles["chat-job-title"]}>{title}</Typography.Text>
      </FlexRow>
    </FlexCol>
  );
};

const PCLandingPageThree = () => {
  const [open, setOpen] = useState(false);

  return (
    <NewPCLandingSubPageLayout
      containerClassName={styles["third-page-container"]}
      className={styles["main-content"]}
      threshold={0.1}
      header={
        <Typography.Title level={2} className={styles["main-content-title"]}>
          500,000+ happy <br /> users’ love
        </Typography.Title>
      }
    >
      <FlexCol className={styles["main-content-sub-container"]}>
        <Image src={"/newimages/landing/love_landing.svg"} width={72} height={72} alt="love" />
        <Typography.Paragraph className={styles["main-content-subtitle"]}>
          Thank you for your praise and suggestions. With your support, we can go further. We hope
          to accompany you throughout your job search journey.
        </Typography.Paragraph>
        <FlexCol size={12} className={styles["chat-container"]}>
          {HOME_LANDING_CHAT_ITEMS.map((item, index) => {
            return <ChatItem key={index} {...item} />;
          })}

          <FlexRow
            className={styles["chat-item"]}
            size={24}
            style={{
              width: "60%",
            }}
          >
            <div className={styles["chat-video-cover"]}></div>
            <FlexCol size={12}>
              <Image src={"/newimages/public/quote.svg"} width={24} height={24} alt="quote" />

              <Typography.Paragraph className={styles["chat-item-paragraph"]}>
                {`It's an AI tool that is just amazing. It scans your resume and finds jobs and tells you within a percentage exactly how likely you are to be a match for that job.`}
              </Typography.Paragraph>
              <FlexRow size={4}>
                <Button
                  id={styles["play-button-id"]}
                  icon={
                    <Image src={"/newimages/public/play.svg"} width={24} height={24} alt="play" />
                  }
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Watch full video
                </Button>
              </FlexRow>
            </FlexCol>
          </FlexRow>
        </FlexCol>
      </FlexCol>

      <Modal
        width={405}
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        closeIcon={<Image src={"/newimages/public/close.svg"} width={16} height={16} alt="close" />}
        className={styles["video-modal-container-id"]}
        destroyOnClose
      >
        <div className={styles["react-player-wrapper"]}>
          <ReactPlayer
            loop
            playing={open}
            url={"https://www.youtube.com/shorts/F2-JR5Sx9mM"}
            width={405}
            height={720}
            config={{
              youtube: {
                playerVars: {
                  autoplay: 1,
                  loop: 1,
                  modestbranding: 1,
                },
              },
            }}
            // muted
            controls={false}
          />
        </div>
      </Modal>
    </NewPCLandingSubPageLayout>
  );
};

export default PCLandingPageThree;
