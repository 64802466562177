import useRegister from "@/hooks/register/useRegister";
import FlexCol from "@/ui/FlexCol";
import FlexRow from "@/ui/FlexRow";
import NewPCLandingSubPageLayout from "@/ui/NewPCLandingSubPageLayout";
import WrapperedIcon from "@/ui/WrapperedIcon";
import { Typography } from "antd";
import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";

import styles from "./index.module.less";

const contentList = [
  {
    icon: "/newimages/landing/aistar_landing.svg",
    embed: "AI Job Match",
    desc: "Job searching is already hard! Increase your odds with AI matched Jobs",
    list: [
      "Apply only to Jobs you are qualified for",
      "Discover matched jobs based on your skills, not only titles",
      "Say goodbye to fake jobs",
      "Apply early with our custom job alerts",
    ],
    picture: "/newimages/landing/n1.png",
    button: "Start Matching",
  },
  {
    icon: "/newimages/landing/edit_landing.svg",
    embed: "Resume AI",
    desc: "Stand out from the crowd with a top notch resume",
    list: [
      "Get a professional quality resume in minutes, not hours",
      "Keep tailoring your resume with AI and catch HR’s eyes in 6 seconds",
      "Rest easy knowing your resume will be ATS compatible",
    ],
    picture: "/newimages/landing/n2.png",
    button: "Improve My Resume",
  },
  {
    icon: "/newimages/landing/social_landing.svg",
    embed: "Insider connections",
    desc: "Network like a pro with our recommended insider connections. Connect, get referrals, and land interviews!",
    list: [
      "Increase your chances of landing an interview by 4X with insider referrals",
      "Easily discover alumni and past colleagues within your target company",
      "Gain access to key connections, such as hiring managers and direct reports",
      "Easily personalize your cold outreach message with our custom templates",
    ],
    picture: "/newimages/landing/n3.png",
    button: "Get Connected",
  },
  {
    icon: "/newimages/landing/chatbot_landing.svg",
    embed: "Orion, your AI Copilot",
    desc: "Job searching can be lonely. Chat with Orion for 24/7 genuine career support",
    list: [
      "Focus your efforts with a more tailored list of jobs",
      "Wow in your interviews with specific company insights",
      "Understand why you are a good fit for a role",
      "Stuck in your job search or career? Get personalized guidance and coaching",
    ],
    picture: "/newimages/landing/n4.png",
    button: "Ask Orion",
  },
];

const LinkButton = ({ primary }: { primary: string }) => {
  const { registerLink } = useRegister();

  return (
    <Link href={registerLink} id={styles["link-button-id"]}>
      {primary}
      <WrapperedIcon
        src="/newimages/public/arr_subnav.svg"
        inner={16}
        outer={24}
        radius={12}
        alt="arrow_right"
      ></WrapperedIcon>
    </Link>
  );
};

const PCLandingPageFour = () => {
  return (
    <NewPCLandingSubPageLayout
      containerClassName={styles["fourth-page-container"]}
      className={classNames(styles["main-content"])}
      threshold={0.05}
      header={
        <Typography.Title className={styles["main-content-title"]} level={2}>
          AI features
        </Typography.Title>
      }
    >
      <FlexCol size={96} className={styles["main-content-detail"]}>
        {contentList.map((item, index) => (
          <FlexCol className={styles["main-content-block"]} key={index}>
            <Typography.Title level={3} className={styles["sub-content-title"]}>
              <Image src={item.icon} width={48} height={48} alt="icon" />
              <b>{item.embed}</b> / <span>{item.desc}</span>
            </Typography.Title>
            <FlexRow className={styles["sub-content-info"]} size={80}>
              <FlexCol className={styles["sub-content-info-left"]}>
                <ul>
                  {item.list.map((content, i) => (
                    <li key={i}>{content}</li>
                  ))}
                </ul>
                <LinkButton primary={item.button}></LinkButton>
              </FlexCol>
              <Image
                className={styles["sub-content-img"]}
                src={item.picture}
                width={560}
                height={560}
                alt="pic"
              />
            </FlexRow>
          </FlexCol>
        ))}
      </FlexCol>
    </NewPCLandingSubPageLayout>
  );
};

export default PCLandingPageFour;
