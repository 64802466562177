import useRegister from "@/hooks/register/useRegister";
import Link from "next/link";
import React from "react";

import styles from "./index.module.less";

type TryJobrightForFreeProps = {
  style?: React.CSSProperties;
  onClick?: () => void;
};

const TryJobrightForFree: React.FC<TryJobrightForFreeProps> = props => {
  const { registerLink } = useRegister();

  return (
    <Link
      style={props?.style}
      onClick={props?.onClick}
      href={registerLink}
      id={styles["try-for-free-button"]}
    >
      Try Jobright for Free
    </Link>
  );
};

export default TryJobrightForFree;
