import { useRequest } from "ahooks";
import { AutoComplete, AutoCompleteProps, Button } from "antd";
import cs from "classnames";
import Image from "next/image";
import React from "react";

import { isEmpty } from "@/utils";

import styles from "./index.module.less";

export type SuggestionsAutoCompleteProps = AutoCompleteProps &
  CustomFormItemProps<string> & {
    remove?: () => void;
    allowRemove?: boolean;
    className?: string;
    api: any;
    placeholder?: string;
  };

const SuggestionAutoComplete: React.FC<SuggestionsAutoCompleteProps> = ({
  value,
  onChange,
  allowRemove,
  remove,
  className,
  api,
  placeholder,
  ...restProps
}) => {
  const { data: autoCompleteOptions } = useRequest(
    () =>
      api({
        input: value,
      }),
    {
      refreshDeps: [value],
      debounceWait: 400,
      ready: !isEmpty(value) ? !!((value as string) ?? "").trim().length : false,
    }
  );

  const onInputChange = (input: string) => {
    if (typeof onChange === "function") {
      onChange(input);
    }
  };

  return (
    <div
      className={cs({
        [styles["suggestion-job-title-auto-complete-container"]]: true,
        [className as string]: !!className,
      })}
    >
      <AutoComplete
        backfill
        value={value as string}
        onChange={onInputChange}
        options={(autoCompleteOptions as string[])?.map(record => ({
          label: record,
          value: record,
        }))}
        placeholder={placeholder}
        className={cs({
          [styles["suggestion-title-auto-complete"]]: true,
        })}
        suffixIcon={restProps?.suffixIcon ?? null}
        popupClassName={
          restProps?.popupClassName
            ? cs({
                [styles["suggestion-title-auto-complete-popup"]]: true,
                [restProps.popupClassName as string]: true,
              })
            : styles["suggestion-title-auto-complete-popup"]
        }
      />

      {allowRemove && (
        <Button
          id={styles["remove-button-id"]}
          className={styles["remove-button"]}
          icon={
            <Image
              src="/newimages/public/close_mini.svg?1"
              width={16}
              height={16}
              alt="del-icon"
              onClick={remove}
            />
          }
        />
      )}
    </div>
  );
};

export default SuggestionAutoComplete;
