import useMarqueeJobs from "@/hooks/landing/useMarqueeJobs";
import NewPCLandingSubPageLayout from "@/ui/NewPCLandingSubPageLayout";
import { Flex, Typography } from "antd";
import Link from "next/link";
import { useRouter } from "next/router";
import { useMemo } from "react";

import { DETAIL_PATHNAME } from "@/constants/jobs/joblist";
import { MARQUEE_CARD_COUNT } from "@/constants/landing/landing";

import LandingCompanyLogo from "../LandingCompanyLogo";
import styles from "./index.module.less";

const PCLandingPageTwo = () => {
  const router = useRouter();
  const jobs = useMarqueeJobs();

  const renderJobList = useMemo(() => {
    return jobs.map((job, index) => (
      <Link
        className={styles["main-job-card"]}
        key={index}
        href={{
          pathname: `${DETAIL_PATHNAME}/${job?.jobResult?.jobId}`,
          query: router.query,
        }}
        target="_blank"
      >
        <LandingCompanyLogo width={60} height={60} logoSrc={job?.jobResult?.jdLogo} />
        <Flex gap={4} vertical>
          <Typography.Text className={styles["main-job-card-company-info"]}>
            {job?.companyResult?.companyName}{" "}
            <Typography.Text className={styles["main-job-card-extra-info"]}>
              · {job?.jobResult?.publishTimeDesc.split(" ")[0]}{" "}
            </Typography.Text>
          </Typography.Text>
          <Typography.Paragraph className={styles["main-job-card-title"]} ellipsis>
            {job?.jobResult?.jobTitle}
          </Typography.Paragraph>
        </Flex>
      </Link>
    ));
  }, [jobs, router.query]);

  return (
    <NewPCLandingSubPageLayout
      containerClassName={styles["second-page-container"]}
      className={styles["main-content"]}
      threshold={0.4}
    >
      <Flex className={styles["main-content-jobs"]} align="center">
        <h3 className={styles["main-title"]}>
          Never Miss Out On New Jobs Again.
          <br />
          Join The Largest Job Board!
        </h3>
        {MARQUEE_CARD_COUNT.map(item => {
          return (
            <div key={item.value} className={styles["main-job-block"]}>
              <Typography.Paragraph className={styles["main-job-block-count"]}>
                {item.value}
              </Typography.Paragraph>
              <Typography.Paragraph className={styles["main-job-block-description"]}>
                {item.desc}
              </Typography.Paragraph>
            </div>
          );
        })}
      </Flex>
      <Flex className={styles["marquee"]}>
        <div className={styles["marquee-content"]}>{renderJobList}</div>
        <div aria-hidden="true" className={styles["marquee-content"]}>
          {renderJobList}
        </div>
      </Flex>
    </NewPCLandingSubPageLayout>
  );
};

export default PCLandingPageTwo;
