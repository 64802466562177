import { SPECIAL_CITY } from "@/enums/filter";
import NewPCLandingSubPageLayout from "@/ui/NewPCLandingSubPageLayout";
import SuggestionAutoComplete from "@/ui/SuggestionAutoComplete";
import { useRequest } from "ahooks";
import { Button, Form, Select, Typography, message } from "antd";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { useState } from "react";

import { fetchOpenCities, fetchSuggestionTitleListV2 } from "@/services/preference-filters";

import { isEmpty, trackEvent } from "@/utils";
import { clearNullOrUndefined } from "@/utils/filter";
import { valueToOptions } from "@/utils/option";

import {
  ENTRY_LEVEL,
  EXPERIENCE_LEVELS_OPTIONS,
  HYBRID_MODE,
  MID_LEVEL,
  ONSITE_MODE,
  REMOTE_MODE,
  SENIOR_LEVEL,
  WORK_MODE_OPTIONS,
} from "@/constants";
import { VISITOR_SEARCH_PATHNAME } from "@/constants/pathname";

import styles from "./index.module.less";

const prefixCls = "eighth-page";

type SearchBarFormValueType = {
  jobTitle?: string;
  workModel?: number[];
  city?: string;
  seniority?: number[];
};

const PCLandingEight = () => {
  const router = useRouter();
  const [messageApi, contextHolder] = message.useMessage();

  const [searchBarFormInstance] = Form.useForm();
  const [options, setOptions] = useState<string[]>([SPECIAL_CITY.within_us]);
  const [focus, setFocus] = useState(false);

  const { loading: loadingCity } = useRequest(fetchOpenCities, {
    ready: focus,
    onSuccess: response => {
      setOptions(currentOptions => {
        return [...currentOptions, ...(response ?? [])];
      });
    },
  });

  const onConfirmSearch = (values: SearchBarFormValueType) => {
    let finalValues = {
      ...values,
    };

    if (finalValues?.city && isEmpty(finalValues?.workModel)) {
      finalValues = {
        ...finalValues,
        workModel: [ONSITE_MODE, HYBRID_MODE],
      };
    } else if (!finalValues?.city) {
      finalValues = {
        ...finalValues,
        workModel: [REMOTE_MODE],
      };
    }

    if (!finalValues?.seniority || isEmpty(finalValues?.seniority)) {
      finalValues = {
        ...finalValues,
        seniority: [ENTRY_LEVEL, MID_LEVEL, SENIOR_LEVEL],
      };
    }

    trackEvent("landing_page_search_submit", {
      ...finalValues,
      jobTypes: [1, 2, 3, 4],
      radiusRange: 50,
    });

    finalValues = clearNullOrUndefined({
      ...finalValues,
      city: finalValues?.city ?? SPECIAL_CITY.within_us,
      workModel: finalValues?.workModel ? finalValues.workModel?.join(",") : "",
      seniority: finalValues?.seniority ? finalValues.seniority?.join(",") : "",
      jobTypes: [1, 2, 3, 4],
      radiusRange: 50,
    });

    const queryString = new URLSearchParams(finalValues as Record<string, string>).toString();
    router.push({
      pathname: VISITOR_SEARCH_PATHNAME,
      query: queryString,
    });
  };

  return (
    <>
      {contextHolder}

      <NewPCLandingSubPageLayout
        containerClassName={styles[prefixCls + "-container"]}
        className={styles[prefixCls + "-content"]}
        threshold={0.05}
        header={
          <Typography.Title className={styles["main-content-title"]} level={2}>
            Find Your Perfect Job in a Click!
          </Typography.Title>
        }
      >
        <Form
          onFinish={onConfirmSearch}
          form={searchBarFormInstance}
          variant="borderless"
          className={styles[prefixCls + "-search-bar-form"]}
        >
          <Form.Item
            name={"jobTitle"}
            rules={[
              {
                validator: async (rule, value) => {
                  if (!value) {
                    messageApi.open({
                      icon: <></>,
                      content: "Please enter a job title",
                      style: {
                        marginBlockStart: 64,
                      },
                    });
                    return Promise.reject();
                  }
                },
              },
            ]}
          >
            <SuggestionAutoComplete
              allowRemove={false}
              api={fetchSuggestionTitleListV2}
              className={styles[prefixCls + "-search-bar-auto-complete"]}
              popupClassName={styles[prefixCls + "-search-bar-select-popup"]}
              placeholder={"Job Title"}
            />
          </Form.Item>
          <Form.Item name="workModel">
            <Select
              className={styles[prefixCls + "-search-bar-select"]}
              popupClassName={styles[prefixCls + "-search-bar-select-popup"]}
              options={WORK_MODE_OPTIONS}
              placeholder="Work Model"
              mode="multiple"
              maxTagCount={1}
              suffixIcon={
                <Image
                  src={"/newimages/public/drop_down.svg"}
                  width={16}
                  height={16}
                  alt="drop_down"
                />
              }
            />
          </Form.Item>
          <Form.Item name="city">
            <Select
              showSearch
              options={valueToOptions(options)}
              popupClassName={styles[prefixCls + "-search-bar-select-popup"]}
              className={styles[prefixCls + "-search-bar-select"]}
              placeholder="City"
              loading={loadingCity}
              onFocus={() => {
                if (!focus) {
                  setFocus(true);
                }
              }}
              suffixIcon={
                <Image
                  src={"/newimages/public/drop_down.svg"}
                  width={16}
                  height={16}
                  alt="drop_down"
                />
              }
            />
          </Form.Item>
          <Form.Item name="seniority">
            <Select
              className={styles[prefixCls + "-search-bar-select"]}
              popupClassName={styles[prefixCls + "-search-bar-select-popup"]}
              mode="multiple"
              options={EXPERIENCE_LEVELS_OPTIONS}
              placeholder="Experience Level"
              maxTagCount={1}
              suffixIcon={
                <Image
                  src={"/newimages/public/drop_down.svg"}
                  width={16}
                  height={16}
                  alt="drop_down"
                />
              }
            />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              className={styles[prefixCls + "-go-btn"]}
              id={styles[prefixCls + "-go-btn-id"]}
              type="default"
            >
              GO
            </Button>
          </Form.Item>
        </Form>
      </NewPCLandingSubPageLayout>
    </>
  );
};

export default PCLandingEight;
