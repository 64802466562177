import FeaturedByOpenai from "@/ui/FeaturedByOpenai";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import { useMount, useUpdateEffect } from "ahooks";
import { Typography } from "antd";
import classnames from "classnames";
import React, { MutableRefObject } from "react";
import ReactPlayer from "react-player/lazy";

import { trackEvent } from "@/utils";

import { LANDING_YOUTUBE_VIDEO_LINK } from "@/constants";

import TryJobrightForFree from "../TryJobrightForFree";
import styles from "./index.module.less";

const PCLandingPageOneV3 = () => {
  const [ref, entry] = useIntersectionObserver({
    threshold: 0.67,
    root: null,
  }) as [MutableRefObject<HTMLDivElement>, IntersectionObserverEntry | null];

  const [playing, setPlaying] = React.useState<boolean>(false);
  const [isEnd, setIsEnd] = React.useState<boolean>(false);

  useUpdateEffect(() => {
    if (entry?.isIntersecting && !playing) {
      setPlaying(true);
      trackEvent("landing_page_new_2nd_exposure");
    }
  }, [entry?.isIntersecting]);

  useMount(() => {
    setTimeout(() => {
      setIsEnd(true);
    });
  });

  return (
    <div className={styles["landing-v3-content"]}>
      <FeaturedByOpenai className={styles["featured-by-openai"]} />
      <Typography.Title className={styles["main-title"]}>
        No More Solo Job Hunting
        <Typography.Paragraph className={styles.emphasis}>
          Do it with AI Copilot
        </Typography.Paragraph>
      </Typography.Title>
      <Typography.Title level={2} className={styles["sub-title"]}>
        Our AI makes landing job interviews dramatically easier and faster! - get matched jobs,
        tailored resume, and recommended insider connections in less than 1 min!
      </Typography.Title>

      <TryJobrightForFree />

      <div
        className={classnames(
          styles["react-player-wrapper"],
          styles["default"],
          !isEnd && styles["started"],
          isEnd && styles["ended"]
        )}
        ref={ref}
      >
        {!playing ? (
          <div className={styles["react-player-cover"]}></div>
        ) : (
          <ReactPlayer
            loop
            url={LANDING_YOUTUBE_VIDEO_LINK}
            width={1200}
            height={675}
            style={{
              borderRadius: 16,
              boxShadow: "0px 32px 80px 0px rgba(0, 30, 60, 0.10)",
              backgroundImage: `url("/newimages/landing/jobs_normal.jpg")`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            config={{
              youtube: {
                playerVars: {
                  autoplay: 0,
                  loop: 1,
                  modestbranding: 1,
                },
              },
            }}
            playing={playing}
            muted
            controls={false}
          />
        )}
      </div>
    </div>
  );
};

export default PCLandingPageOneV3;
