import NewPCLandingSubPageLayout from "@/ui/NewPCLandingSubPageLayout";
import WrapperedIcon from "@/ui/WrapperedIcon";
import { Typography } from "antd";

import { TRY_JOBRIGHT_FOR_FREE_BUTTON_STYLE } from "@/constants/landing/landing";

import TryJobrightForFree from "../TryJobrightForFree";
import styles from "./index.module.less";

const PCLandingPageSeven = () => {
  return (
    <NewPCLandingSubPageLayout
      containerClassName={styles["seventh-page-container"]}
      className={styles["main-content"]}
    >
      <WrapperedIcon
        src="/newimages/public/rocket.svg"
        alt="rocket"
        outer={120}
        radius={60}
        inner={60}
      />
      <Typography.Title level={2} className={styles["main-content-title"]}>
        Ensure a Fast and Successful Journey to Your Next Career Move
      </Typography.Title>
      <TryJobrightForFree style={TRY_JOBRIGHT_FOR_FREE_BUTTON_STYLE} />
    </NewPCLandingSubPageLayout>
  );
};

export default PCLandingPageSeven;
