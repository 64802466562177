import GlobalFooter from "@/ui/GlobalFooter";
import PCLandingHeader from "@/ui/PCLandingHeader";
import { Layout } from "antd";
import cs from "classnames";

import PCLandingPageOne from "@/components/Landing/PCLandingPageOne";
import PCLandingPageOneWrapper from "@/components/Landing/PCLandingPageOneWrapper";
import PCLandingPageTwo from "@/components/Landing/PCLandingPageTwo";

import PCLandingEight from "./PCLandingPageEight";
import PCLandingPageFive from "./PCLandingPageFive";
import PCLandingPageFour from "./PCLandingPageFour";
import PCLandingPageSeven from "./PCLandingPageSeven";
import PCLandingPageSix from "./PCLandingPageSix";
import PCLandingPageThree from "./PCLandingPageThree";
import styles from "./index.module.less";

const PCLandingPage = () => {
  return (
    <Layout className={cs(styles["landing-page-layout"], styles["landing-page-layout-overwrite"])}>
      <PCLandingPageOneWrapper className={styles["landing-v3-wrapper"]}>
        <PCLandingHeader />
        <PCLandingPageOne />
      </PCLandingPageOneWrapper>
      <PCLandingPageTwo />
      <PCLandingPageThree />
      <PCLandingPageFour />
      <PCLandingPageFive />
      <PCLandingPageSix />
      <PCLandingEight />
      <PCLandingPageSeven />
      <GlobalFooter />
    </Layout>
  );
};

export default PCLandingPage;
