import FlexRow from "@/ui/FlexRow";
import Image from "next/image";

export const valueToOptions = (value: string[]) => {
  return (value || []).map(item => ({
    label: item,
    value: item,
  }));
};

export const recordToOptions = (
  target: Record<string, unknown>[],
  valueField: string,
  labelField: string,
  logoField?: string
): { label: React.ReactNode; value: string }[] => {
  return (target || []).map(item => ({
    label: (
      <FlexRow size={8}>
        {!!(logoField && item[logoField]) && (
          <Image src={item[logoField] as string} alt="linkedin logo" width={20} height={20} />
        )}
        {item[labelField] as string}
      </FlexRow>
    ),
    value: item[valueField] as string,
    originData: item,
  }));
};
