import FlexCol from "@/ui/FlexCol";
import FlexRow from "@/ui/FlexRow";
import NewPCLandingSubPageLayout from "@/ui/NewPCLandingSubPageLayout";
import { Typography } from "antd";
import Image from "next/image";
import React from "react";

import { LANDING_CHAT_ITEMS } from "@/constants/landing/landing";

import styles from "./index.module.less";

const ChatItem = ({
  paragraph,
  name,
  avatar,
  title,
  width,
}: {
  paragraph: string;
  name: string;
  avatar: string;
  title: string | React.ReactNode;
  width: string;
}) => {
  return (
    <FlexCol
      className={styles["chat-item"]}
      size={24}
      style={{
        width,
      }}
    >
      <FlexRow className={styles["chat-item-header"]}>
        <Image src={"/newimages/public/quote.svg"} width={24} height={24} alt="quote" />
        <FlexRow size={24}>
          <FlexCol>
            <Typography.Text className={styles["chat-username"]}>{name}</Typography.Text>
            <Typography.Text className={styles["chat-job-title"]}>{title}</Typography.Text>
          </FlexCol>
          <Image
            className={styles["chat-avatar"]}
            src={avatar}
            width={80}
            height={80}
            alt="avatar"
          />
        </FlexRow>
      </FlexRow>
      <Typography.Paragraph className={styles["chat-item-paragraph"]}>
        {paragraph}
      </Typography.Paragraph>
    </FlexCol>
  );
};

const PCLandingPageFive = () => {
  return (
    <NewPCLandingSubPageLayout
      containerClassName={styles["fifth-page-container"]}
      className={styles["main-content"]}
      threshold={0.1}
      header={
        <Typography.Title level={2} className={styles["main-content-title"]}>
          Trusted by <br />
          Industry Leaders
        </Typography.Title>
      }
    >
      <FlexCol className={styles["main-content-sub-container"]}>
        <FlexCol size={12} className={styles["chat-container"]}>
          {LANDING_CHAT_ITEMS.map((item, index) => {
            return <ChatItem key={index} {...item} />;
          })}
        </FlexCol>
      </FlexCol>
    </NewPCLandingSubPageLayout>
  );
};

export default PCLandingPageFive;
